import { Group, Text } from "@mantine/core"

type Props = {
  category: string
  color: string
}

export const CategoryTitle = ({ category, color }: Props) => (
  <Group
    bg={`${color}.0`}
    px={12}
    py={8}
    style={{ borderRadius: 6, alignSelf: "flex-start" }}
  >
    <Text fw={600} fz="xxl">
      {category}
    </Text>
  </Group>
)
