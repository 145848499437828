import { Group, Text } from "@mantine/core"

import { TablerIconComponent } from "@kiosk/front/utils/icon"

type Props = {
  name: string
  icon?: TablerIconComponent
  color?: string
}

// TODO: add icons to each topic in Dato
//   How ?
//   - Add all icons as svg/img in the assets folder
//   - Add a field in the topic model referencing a tabler icon (ex: Topic.icon = "IconHome", then do a dynamic import from @tabler/icons-react)
export const TopicTitle = ({
  name,
  // icon: Icon, color = "green"
}: Props) => (
  <Group gap={16}>
    {/*<ThemeIcon*/}
    {/*  variant="light"*/}
    {/*  color="green"*/}
    {/*  bg={`${color}.0`}*/}
    {/*  radius="xl"*/}
    {/*  w={40}*/}
    {/*  h={40}*/}
    {/*>*/}
    {/*  {Icon ? <Icon /> : <IconQuestionMark />}*/}
    {/*</ThemeIcon>*/}
    <Text fw={600} size="lg">
      {name}
    </Text>
  </Group>
)
